import * as React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";

const Blog = () => {
  const { getBlogged, allStrapiBlog } = useStaticQuery(graphql`
    query GetBlogImage {
      getBlogged: file(
        relativePath: { eq: "tyler-nix-2rZq49uGxLk-unsplash.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            height: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      allStrapiBlog(sort: { fields: published_at, order: DESC }) {
        edges {
          node {
            id
            Title
            Snippet
            metadescription
            metatitle
            slug
            published_at
            dateReadable: published_at(formatString: "MMMM DD, YYYY")
            image {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 350, placeholder: BLURRED)
                  seoImage: gatsbyImageData(
                    width: 1200
                    height: 630
                    quality: 100
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const getBloggedImageData = getImage(getBlogged);

  const BlogItem = ({
    id,
    title,
    snippet,
    slug,
    published_at,
    author,
    dateReadable,
    image,
  }) => {
    let slugWTrailingSlash = `/blog/${slug}/`;

    return (
      <div
        class="flex flex-col rounded-lg bg-white shadow-lg overflow-hidden"
        key={id}
      >
        <article className={`blog-list-item tile is-child box notification`}>
          <GatsbyImage
            className="h-48 w-full object-cover rounded-t-xl mb-3"
            image={getImage(image && image.localFile)}
            alt=""
          />

          <div class="flex-1 p-6 flex flex-col justify-between">
            <div class="flex-1">
              <p class="text-sm font-medium text-brand-600">
                <span class="hover:underline">Article</span>
              </p>

              <Link className="block mt-2" to={`${slugWTrailingSlash}`}>
                <p class="text-xl font-semibold text-gray-900">{title}</p>
                <p class="mt-3 text-base text-gray-500">
                  {snippet && snippet.substr(0, snippet.indexOf("."))}.
                </p>
              </Link>
            </div>
            <div class="mt-6 flex items-center">
              <div class="flex-shrink-0">
                <a href="#">
                  <span class="sr-only">{author}</span>
                </a>
              </div>
              <div>
                <p class="text-sm font-medium text-gray-900">{author}</p>
                <div class="flex space-x-1 text-sm text-gray-500">
                  <time datetime={published_at}>{dateReadable}</time>
                  <span aria-hidden="true">&middot;</span>

                  <Link className="button" to={`${slugWTrailingSlash}`}>
                    Keep Reading →
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    );
  };
  return (
    <Layout bg={"gray"} header="light">
      <SEO title={"Blog"} description={"LocalTier Blog"} />

      <section className="bg-white">
        <div className="max-w-7xl  mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-2 md:mb-4">
              LocalTier Blog
            </h1>
            <p className="text-xl md:text-2xl font-display capitalize">
              Sail through the sea of noise with ease.
            </p>
            <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {allStrapiBlog &&
                allStrapiBlog.edges.map((blog, b) => {
                  const {
                    Title,
                    Snippet,
                    slug,
                    published_at,
                    id,
                    dateReadable,
                    image,
                  } = blog.node;
                  return (
                    <BlogItem
                      title={Title}
                      slug={slug}
                      snippet={Snippet}
                      id={id}
                      published_at={published_at}
                      dateReadable={dateReadable}
                      author={"Christine Pilla"}
                      image={image}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blog;
